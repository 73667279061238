<template>
  <div class="bg bg1" >
    <div class="class--con">
      <class-but i-s-throw-out @backOfff="backOfff" @forward="forward"/>
      <img :src="imgUrl" alt="">
    </div>

  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";

export default {
  name: "seventeenPage",
  components: {ClassBut},
  data() {
    return {
      imgUrl: [],
      index:0,
    }
  },
  created() {
    this.gitApicourseTalk()
  },
  methods: {
    forward() {
      if ( this.index+1 < this.imgUrl1.length ) {
        this.index++
        this.imgUrl =  this.imgUrl1[this.index].imgUrl
        // console.log(this.imgUrl1[this.index].imgUrl)
      }else {
        this.$router.push({
          name: 'videoPresentation1',
          query: this.$route.query,
        })
      }

    },
    backOfff(){
      if ( this.index > 0 ) {
        this.index--
        this.imgUrl =  this.imgUrl1[this.index].imgUrl
      }else {
        this.$router.back(-1)
      }
    },
    async gitApicourseTalk() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/searchExpandCourse`, {
        classId: this.$route.query.classId
      })
      this.imgUrl = res[0].imgUrl
      this.imgUrl1 = res

    },
  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";
.class--con{
  text-align: center;
  img{
    height: 80%;
    object-fit: contain;
    margin-top: 100px;
    width: 80%;
  }
}
</style>
